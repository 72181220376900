import React from 'react'
import { BrowserRouter as Router } from "react-router-dom"
import DateAdapter from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles'
import { grey } from '@mui/material/colors'

import { ProvideAuth } from './login/authContext'

function AppProviders({children}) {
  let theme = createTheme({
    typography: {
      fontFamily: [
        'Montserrat-Regular',
        '"Helvetica"',
        '"Arial"',
        'sans-serif'
      ].join(','),
      h3: {
        fontFamily: 'Montserrat-Medium, "Helvetica", sans-serif'
      },
      h4: {      
        fontFamily: 'Montserrat-Medium, "Helvetica", sans-serif',  
        fontSize: '1.5rem',
      },
      subtitle1: {
        fontFamily: 'Montserrat-Medium, "Helvetica", sans-serif',
      },
      subtitle2: {
        fontFamily: 'Montserrat-Medium, "Helvetica", sans-serif',
        color: grey[600]
      },
      body2: {
        fontFamily: 'Montserrat-Light, "Helvetica", sans-serif'
      }
    }
  })

  theme = responsiveFontSizes(theme)
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={DateAdapter}>
        <ProvideAuth>
          <Router>
            { children }
          </Router>
        </ProvideAuth>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default AppProviders