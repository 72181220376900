import { createContext, useContext, useState } from "react";

import { login, logout } from './loginClient'

const authContext = createContext();

const localStorageAuthData = localStorage.getItem('authenticatedUser') || null

function useProvideAuth() {
  const [authData, setAuthData] = useState(JSON.parse(localStorageAuthData))

  const signin = async (cb, formInputs) => {
    try {
      const authenticatedUser = await login(formInputs)
      setAuthData({ ...authenticatedUser })
      localStorage.setItem('authenticatedUser', JSON.stringify({ ...authenticatedUser }))
      return cb(true)

		} catch (error) {
			console.log(error)
      return cb(false)
		}
  }

  const signout = async (cb) => {
    try {
      await logout(authData.token)
      localStorage.removeItem('authenticatedUser')
      setAuthData(null)
      return cb()

    } catch(error) {
      console.log(error)
    }
  }

  return {
    authData,
    signin,
    signout
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}