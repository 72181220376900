import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function Loading() {
  return (
    <Box p={2}>
      <Typography variant="subtitle1" align="center">Loading...</Typography>
    </Box>
  )
}

export default Loading