import React from 'react'
import { useHistory } from 'react-router'
import { Link, useLocation } from 'react-router-dom'

// import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import { useAuth } from '../login/authContext'

function Footer() {
  
  const location = useLocation();
  const history = useHistory();
  const auth = useAuth();

  const StyledLink = styled(Link)(() => ({
    textDecoration: 'none',
    '& a:hover, & a:focused': {
      textDecoration: 'none'
    }
  }))

  if (location.pathname === '/login') return null

  if (!auth.authData) {
    return (
      <Grid container spacing={1} my={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} align="center">
          <StyledLink to="/admin/login">
            <Button variant="outlined">Login</Button>
          </StyledLink>
        </Grid>
      </Grid>
    )
  }

  if (location.pathname.includes('admin')) {
    return (
      <Grid container spacing={1} my={2} justifyContent="center" alignItems="center">
        <Grid item xs={10}>
          <Typography variant="body1" align="center">{`Hi, ${auth.authData.user.name}!`}</Typography>
        </Grid>
        <Grid item xs={10} align="center">
          <Button variant="outlined" onClick={() => { auth.signout(() => history.push("/")) }}>
            Sign out
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container spacing={1} my={2} justifyContent="center" alignItems="center">  
      <Grid item xs={12}>
        <Typography variant="body1" align="center">{`Hi, ${auth.authData.user.name}!`}</Typography>
      </Grid>
      <Grid item align="center">
        <Link to="/admin/dashboard" sx={{ textDecoration: "none" }}>
          <Button variant="outlined">Dashboard</Button>
        </Link>
      </Grid>
      <Grid item align="center">
        <Button variant="outlined" onClick={() => { auth.signout(() => history.push("/")) }}>
          Sign out
        </Button>
      </Grid>
    </Grid>
  )
}

export default Footer