import React from 'react'

import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'

import { useAuth } from '../login/authContext'

import Footer from '../common/Footer'
import Loading from './Loading'

const AuthenticatedApp = React.lazy(() => import('./AuthenticatedApp'))
const UnauthenticatedApp = React.lazy(() => import('./UnauthenticatedApp'))

function Home() {
  let { authData } = useAuth();
  return (
    <React.Suspense fallback={<Loading />}>
      <Container mb={4} disableGutters maxWidth={false} sx={{ minHeight: '80vh' }}>
        {
          authData ? <AuthenticatedApp /> : <UnauthenticatedApp />
        }
      </Container>
      <Divider variant="middle" />
      <Footer />
    </React.Suspense> 
  )
}

export default Home