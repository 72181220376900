import React from 'react'
import ReactDOM from 'react-dom'

import './index.css';
import AppProviders from './AppProviders'
import Home from './home/Home'

function App() {
  return (
    <AppProviders>
      <Home />
    </AppProviders>
  )
}

ReactDOM.render(<App />, document.getElementById('root'));