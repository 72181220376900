async function client(endpoint, { data, token, headers: customHeaders, ...customConfig } = {}) {

  const defaultHeaders = {
    Authorization: token ? `Bearer ${token}` : undefined,
    'Content-Type': data ? 'application/json' : undefined,
  }

  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...defaultHeaders,
      ...customHeaders
    },
    // e.g. used by add-relationship to specify PATCH method
    ...customConfig
  }

  return window.fetch(`/api/${endpoint}`, config).then(async response => {
    if (response.ok) {
      const data = await response.json()
      return data
    } else {
      const errorMessage = await response.text()
      return Promise.reject(new Error(errorMessage))
    }
  
  })
}

export { client }