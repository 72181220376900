import { client } from '../utils/apiClient'

function login(formData) {
  return client('users/login', {
    method: 'POST',
    data: formData
  })
}

function logout(token) {
  return client('users/logout', {
    method: 'POST',
    token
  })
}

export { login, logout }